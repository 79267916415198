import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const CardPolySans = ({ content }) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    const funText1 = "Get world-class creative work on-demand to scale your brand with ease — and at breakneck speed."
    const funText2 = "Thank you very very much"

    const [isActiveFw, setActiveFw] = useState(false)

    const toggleClassFw = () => {
        setActiveFw(!isActiveFw);
    };

    const [isActiveFs, setActiveFs] = useState(false)

    const toggleClassFs = () => {
        setActiveFs(!isActiveFs);
    };

    const [fontSizes, changeFontSizes] = window.innerWidth > 1079 ? useState({
        xs: 18,
        sm: 30,
        md: 48,
        lg: 60,
        xl: 90
    }) : useState({
        xs: 14,
        sm: 18,
        md: 22,
        lg: 28,
        xl: 36
    })
    
    const [fs, toggleFs] = useState(fontSizes.xl)
    const [fw, toggleFw] = useState(400)
    const [textValue, toggleTextValue] = useState("Type something here to try PolySans")

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)

        gsap.to('#fun-typography', {
            ease: "none",
            rotation: 360,
            duration: 15,
            repeat: -1
        })

        ScrollTrigger.create({
            trigger: thisElement.current,
            start: "top 75%",
            onEnter: () => {
                if (document.querySelectorAll("#fun-text-1 span").length) {
                    gsap.to("#fun-text-1 .char", {
                        ease: "power3.out",
                        duration: 2,
                        x: 0,
                        y: 0,
                        opacity: 1,
                        stagger: .04,
                        delay: 0
                    })
                }
            }
        })

        gsap.registerPlugin(MotionPathPlugin)

        let tl = gsap.timeline({
                repeat: -1
            }).timeScale(0),
            dur = 10,
            each = dur * 0.02

        tl.totalTime(tl.duration() * 100)

        function animateLetters() {
            let progress = tl.progress()
            tl.totalProgress(0).clear()
            document.querySelectorAll("#fun-text-2 .char").forEach((char, i) => {
                let timeOffset = i * each,
                    startTime = dur / 1 + timeOffset,
                    pathOffset = startTime / dur

                tl.to(char, {
                    motionPath: {
                        path: '#fun-text-2-path',
                        align: '#fun-text-2-path',
                        alignOrigin: [0.5, 0.5],
                        autoRotate: true,
                        start: pathOffset,
                        end: 1 + pathOffset
                    },
                    immediateRender: true,
                    duration: 10,
                    ease: 'none',
                }, 0);
            })
            tl.progress(progress)

            gsap.to(tl, {timeScale: 1, ease:"none", overwrite: true})
        }

        window.addEventListener("resize", animateLetters)
        animateLetters()

        window.addEventListener('resize', () => {
            if (window.innerWidth > 1079) {

                toggleFs(90)
                toggleFw(400)

                changeFontSizes({
                    xs: 18,
                    sm: 30,
                    md: 48,
                    lg: 60,
                    xl: 90
                })
            } else {

                toggleFs(36)
                toggleFw(400)

                changeFontSizes({
                    xs: 14,
                    sm: 18,
                    md: 22,
                    lg: 28,
                    xl: 36
                })
            }
        })
    }, [])

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    const funText1toChar = () => {
        const splitText = funText1.match(/[^\s-]+-?/g)

        return (
            <>
                {
                    splitText.map((word, idx) =>
                        <span className="word" key={idx}>
                            {
                                word.split("").map((char, idx) => <span className={`char${(word == "scale" || word == "speed.") ? " bg-canary" : ""}`} key={idx}>{char}</span>)
                            }
                        </span>
                    )
                }
            </>
        )
    }

    const funText2toChar = () => {
        const splitText = funText2.split("")

        return (
            <>
                {
                    splitText.map((char, idx) =>
                        <span className="char" key={idx}>
                            {char}
                        </span>
                    )
                }
            </>
        )
    }

    return (
        <div className="card slide-from-bottom" id="cardPolySans">
            <div className="card__header">
                <span>PolySans</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row my-2">
                        <div className="column">
                            <p className="small">PolySans is our most versatile typeface, frequently used for body copy.</p>
                        </div>
                    </div>
                    <div className="row px-2 mt-4">
                        <div className="column column-medium-third px-0 border-row">
                            <div className="border-around p-1">
                                <div className="border-around p-2">
                                    <span id="fun-text-1">
                                        {funText1toChar()}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="column column-medium-third px-0 border-row mt-3 mt-medium-0">
                            <div className="border-around p-1">
                                <div className="border-around p-2 bg-canary">
                                    <svg xmlns="http://www.w3.org/2000/svg" id="fun-text-2-svg" viewBox="0 0 300 235" xmlSpace="preserve">
                                        <path id="fun-text-2-path" d="M935,943L935,943C978.274,941.991 1000.139,974.618 1027.208,1002.4950000000001C1053.1950000000002,1025.1480000000001 1091.29,1030.595 1127.276,1002.4950000000001C1157.646,975.6830000000001 1161.156,937.5110000000001 1145.646,906.3950000000001C1128.154,874.3400000000001 1087.7369999999999,859.0160000000001 1054.091,869.6630000000001C1015.005,883.4590000000001 998.427,913.2580000000002 999.419,951.4330000000001C1001.449,992.32 1014,988.4720000000001 1013,1021.6550000000001C1012.1800000000001,1062.729 981.163,1098.53 935,1098.439C895.5590000000001,1098.126 857.659,1068.295 857.388,1021.655C857.608,971.97 900.263,942.7 935,943" transform="translate(-856.388 -865.147)" fill="none" stroke="red" />
                                    </svg>
                                    <span id="fun-text-2">
                                        {funText2toChar()}
                                    </span>
                                    <img id="fun-typography" src="./img/typography-logo.svg" alt=""></img>
                                </div>
                            </div>
                        </div>
                        <div className="column column-medium-third px-0 border-row mt-3 mt-medium-0">
                            <div className="border-around p-1">
                                <div className="border-around p-2 bg-eggshell">
                                    <p id="fun-text-3"><b>We believe creativity</b> holds the power to turn any aspiring brand into something truly amazing. But more often than not, it gets weighed down by sloppy communication and obscure work methods. In other words, creativity seems to be stuck in a rut. And we believe it’s time to set it free.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mt-medium-0">
                        <div className="column border-column">
                            <div className="border-around px-2 py-5">
                                <div>
                                    <div className={`select${isActiveFw ? "": " hidden"}`} onClick={() => toggleClassFw()}>
                                        <span>Weight</span>
                                        <ul className="select-input" style={{height: 49.5 * 3 + 1}}>
                                            <li onClick={() => toggleFw(400)} className="select-input__item">400</li>
                                            <li onClick={() => toggleFw(500)} className="select-input__item">500</li>
                                            <li onClick={() => toggleFw(600)} className="select-input__item">600</li>
                                        </ul>
                                    </div>
                                    <div className={`select${isActiveFs ? "": " hidden"}`} onClick={() => toggleClassFs()}>
                                        <span>Size</span>
                                        <ul className="select-input" style={{height: 49.5 * 5 + 1}}>
                                            <li onClick={() => toggleFs(fontSizes.xs)} className="select-input__item">{fontSizes.xs}</li>
                                            <li onClick={() => toggleFs(fontSizes.sm)} className="select-input__item">{fontSizes.sm}</li>
                                            <li onClick={() => toggleFs(fontSizes.md)} className="select-input__item">{fontSizes.md}</li>
                                            <li onClick={() => toggleFs(fontSizes.lg)} className="select-input__item">{fontSizes.lg}</li>
                                            <li onClick={() => toggleFs(fontSizes.xl)} className="select-input__item">{fontSizes.xl}</li>
                                        </ul>
                                    </div>
                                </div>
                                <textarea spellCheck="false" className={`ff-polysans fs-${fs} fw-${fw}`} id="polysans-textarea" value={textValue} onChange={(event) => toggleTextValue(event.target.value)}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardPolySans