import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const CardManifesto = ({content}) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)
    const thisSpinnerElement = useRef(null)

    useEffect(() => {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: thisElement.current,
            start: "top 100%",
            end: "bottom 0%",
            scrub: 2
          },
        })
        .from(thisSpinnerElement.current, {
          rotation: 180,
          duration: 30,
          ease: "none"
        })
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardManifesto">
            <div className="card__header">
                <span>Manifesto</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row my-2">
                        <div className="column column-small-half">
                            <p className="small pe-medium-7">Our manifesto is a glimpse into our company; who we are and what we believe in. It’s meant to connect emotionally with our audience, as well as unite current and future coworkers.</p>
                        </div>
                    </div>
                    <div className="border-around">
                        <div className="row">
                            <div className="column column-medium-40">
                                <div ref={thisSpinnerElement} className="logo-spinner">
                                    <img src="./img/logotype.svg" alt=""></img>
                                </div>
                            </div>
                            <div className="column column-medium-60 mt-4 mt-medium-0">
                                <p><b>We believe creativity</b> holds the power to turn any aspiring brand into something truly amazing. But more often than not, it gets weighed down by sloppy communication and obscure work methods. In other words, creativity seems to be stuck in a rut. And we believe it’s time to set it free.</p>
                                <p><b>We believe true talent</b> knows no borders. Unrestricted by geography, we’re growing a diverse team of the world’s best and most ambitious creatives. Covering all 24 time zones, the lights never go out in our virtual workshop — together we grow bigger and better by the day.</p>
                                <p><b>We believe smooth workflows</b> are key to sustained creativity. And in a world full of multi-authored email threads and files stuck in god knows how many clouds, we’re set on building the most seamless and intuitive platform for creative efforts — a true safe haven for great ideas.</p>
                                <p><b>We believe fast turnarounds</b> heighten creative output. While improved communication and purpose-built technology constantly shorten the gap between briefing and delivery, our creatives are simultaneously left with more clarity and mental space to push their work to the limit and beyond.</p>
                                <p><b>We believe creativity</b> can provide the power to build the world’s most inspiring brands. It just needs a bit of help shaking off its limitations.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardManifesto