import React from "react"

const VideoHead = () => {
    return (
        <section className="head">
            <div className="container">
                <div className="row">
                    <div className="column-medium-20 column-large-25 slide-from-bottom">
                        <div className="title">Video Style</div>
                    </div>
                    <div className="column-medium-75 full-height slide-from-bottom mt-3 mt-medium-0">
                        <h1>Use movement to your advantage</h1>
                        <strong>Video is a great medium to convey more in a short amount of time. Not only more in regards to information, but also in conveying emotion and sparking curiosity. Our video style is similar to our image and illustration style - minimal but fun. We mainly use video to show off our amazing team, or to convey inspiration and spark creativity and excitement.</strong>
                        <a onClick={e => {document.getElementById("cardVideo").scrollIntoView({behavior: "smooth"})}} className="arrow-down-button mt-7"></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoHead