import React from "react"

import BrandHead from "../content/brandHead"
import BottomNavbar from "../components/bottomNavbar/bottomNavbar"

import CardCommunication from "../content/cardCommunication"
import CardManifesto from "../content/cardManifesto"
import CardMessagingHouse from "../content/cardMessagingHouse"
import CardTonality from "../content/cardTonality"
import CardIRL from "../content/cardIRL"

class Brand extends React.Component {
    constructor(props){
      super(props)
    }

    render(){
        return (
            <>
                <BrandHead />
                <section>
                    <div className="container pt-0">
                        <div className="row">
                            <div className="column">
                                <CardCommunication />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardManifesto />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardMessagingHouse />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardTonality />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardIRL updateLocation={this.props.updateLocation} />
                            </div>
                        </div>
                    </div>
                </section>
                <BottomNavbar 
                    updateLocation={this.props.updateLocation}
                    prevLink={"Video Style"}
                    prevHref={"/video"}
                    nextLink={"Logo"} 
                    nextHref={"/logo"} />
            </>
        )
    }
}

export default Brand