import React from "react"

import VideoHead from "../content/videoHead"
import BottomNavbar from "../components/bottomNavbar/bottomNavbar"

import CardVideo from "../content/cardVideo"

class Video extends React.Component {
    constructor(props){
      super(props)
    }

    render(){
        return (
            <>
                <VideoHead />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="column">
                                <CardVideo />
                            </div>
                        </div>
                    </div>
                </section>
                <BottomNavbar 
                    updateLocation={this.props.updateLocation}
                    prevLink={"Image Style"}
                    prevHref={"/photography"}
                    nextLink={"Our Brand"} 
                    nextHref={"/"} />
            </>
        )
    }
}

export default Video