import React from "react"

import PhotographyHead from "../content/photographyHead"
import BottomNavbar from "../components/bottomNavbar/bottomNavbar"

import CardPeople from "../content/cardPeople"
import CardStillLife from "../content/cardLife"

class Photography extends React.Component {
    constructor(props){
      super(props)
    }

    render(){
        return (
            <>
                <PhotographyHead />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="column">
                                <CardPeople />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardStillLife />
                            </div>
                        </div>
                    </div>
                </section>
                <BottomNavbar 
                    updateLocation={this.props.updateLocation}
                    prevLink={"Illustration Style"}
                    prevHref={"/illustration"}
                    nextLink={"Video Style"} 
                    nextHref={"/video"} />
            </>
        )
    }
}

export default Photography