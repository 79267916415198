import React from "react"

const IllustrationHead = () => {
    return (
        <section className="head">
            <div className="container">
                <div className="row">
                    <div className="column-medium-20 column-large-25 slide-from-bottom">
                        <div className="title">Illustration Style</div>
                    </div>
                    <div className="column-medium-75 full-height slide-from-bottom mt-3 mt-medium-0">
                        <h1 className="column-large-75 ps-0">We use 3D and minimalist graphic illustrations.</h1>
                        <strong>Our illustration style is minimal, yet powerful. We love the clean Scandinavian style, but we can’t help but play around when it comes to illustrations.</strong>
                        <a onClick={e => {document.getElementById("cardIllustrations").scrollIntoView({behavior: "smooth"})}} className="arrow-down-button mt-7"></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default IllustrationHead