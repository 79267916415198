import React from "react"

import IllustrationHead from "../content/illustrationHead"
import BottomNavbar from "../components/bottomNavbar/bottomNavbar"

import CardIllustrations from "../content/cardIllustrations"
import CardPatterns from "../content/cardPatterns"
import CardPictograms from "../content/cardPictograms"
import CardCategoryIcons from "../content/cardCategoryIcons"
import CardIcons from "../content/cardIcons"

class Illustration extends React.Component {
    constructor(props){
      super(props)
    }

    render(){
        return (
            <>
                <IllustrationHead />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="column">
                                <CardIllustrations />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardPatterns />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardPictograms />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardCategoryIcons />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardIcons />
                            </div>
                        </div>
                    </div>
                </section>
                <BottomNavbar 
                    updateLocation={this.props.updateLocation}
                    prevLink={"Typography"}
                    prevHref={"/typography"}
                    nextLink={"Image Style"} 
                    nextHref={"/photography"} />
            </>
        )
    }
}

export default Illustration