import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import Chat from "./chat"

const CardIRL = ({content}) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardIRL">
            <div className="card__header">
                <span>Implementation</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row px-2 mt-4">
                        <div className="column column-medium-third mt-3 mt-medium-0 px-0 border-row">
                            <div className="border-around">
                                <img className="device" src="./img/phone-1.png" alt=""></img>
                            </div>
                        </div>
                        <div className="column column-medium-third mt-3 mt-medium-0 px-0 border-row">
                            <div className="border-around">
                                <img className="device" src="./img/phone-2.png" alt=""></img>
                            </div>
                        </div>
                        <div className="column column-medium-third mt-3 mt-medium-0 px-0 border-row">
                            <div className="border-around">
                                <img className="device" src="./img/phone-3.png" alt=""></img>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="column column-small-half">
                            <div className="border-around">
                                <span className="border-around__title">Story</span>
                                <video className="mt-2 irl-element" autoPlay muted loop playsInline>
                                    <source src={'../video/1_3.mp4'} type="video/mp4"></source>
                                </video>
                            </div>
                            <div className="border-around">
                                <span className="border-around__title">Communication</span>
                                <Chat />
                            </div>
                        </div>
                        <div className="column column-small-half mt-4 mt-small-0">
                            <div className="border-around">
                                <span className="border-around__title">Posts</span>
                                <img className="mt-2 irl-element" src="../img/irl-posts.svg" alt=""></img>
                            </div>
                            <div className="border-around">
                                <span className="border-around__title">E-mail</span>
                                <span className="mt-2 irl-element">
                                    <p>
                                        Hi Carl!
                                        <br/>
                                        Thanks for a great start-up meeting, we’re excited to get started!
                                        <br/>
                                        <br/>Attached is the presentation we went through today, including an overview of our process and the project plan we discussed.
                                        <br/>
                                        <br/>To prepare for next weeks workshop it would be great if you can send over any information, you have that might be helpful for us. Examples listed below.
                                        <br/>Visual Identity, vision/mission, target group (and any data you have), who you see as you main competitors.
                                        <br/>
                                        <br/>Let me know if you have any question, looking forward to talking more next week!
                                        <br/>
                                        <br/>Regards,
                                        <br/>Anna Olsson
                                        <br/>Project Manager
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row px-2 mt-3">
                        <div className="column column-medium-70 mt-3 mt-medium-0 px-0 border-row">
                            <div className="border-around">
                                <img className="device pt-0 device-website" src="./img/website.png" alt=""></img>
                            </div>
                        </div>
                        <div className="column column-medium-30 mt-3 mt-medium-0 px-0 border-row">
                            <div className="border-around">
                                <img className="device pt-0" src="./img/phone-4.png" alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardIRL