import React from "react"

import LogoHead from "../content/logoHead"
import BottomNavbar from "../components/bottomNavbar/bottomNavbar"
import PrimaryLogo from "../content/cardPrimaryLogo"
import Wordmark from "../content/cardWordmark"
import Symbol from "../content/cardSymbol"
import Usage from "../content/cardUsage"
import Misuse from "../content/cardMisuse"

class Logo extends React.Component {
    constructor(props){
      super(props)
    }

    render(){
        return (
            <>
                <LogoHead />
                <section>
                    <div className="container pt-0">
                        <div className="row">
                            <div className="column">
                                <PrimaryLogo />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <Wordmark />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <Symbol />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <Usage fold={true} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <Misuse fold={true} />
                            </div>
                        </div>
                    </div>
                </section>
                <BottomNavbar  
                    updateLocation={this.props.updateLocation}
                    prevLink={"Our Brand"}
                    prevHref={"/"}
                    nextLink={"Brand Colour"} 
                    nextHref={"/colours"} />
            </>
        )
    }
}

export default Logo