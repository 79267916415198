import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const CardBrandColours = ({ content }) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardBrandColours">
            <div className="card__header">
                <span>Brand Colours</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row my-2">
                        <div className="column">
                            <p className="small">Primary colours</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column column-medium-40">
                            <div className="background background-colours">
                                <div className="bg bg-eggshell">
                                    Eggshell
                                    <div className="background__info">
                                        <span>HEX: #F2E9D5</span>
                                        <span>RGB: 242, 233, 213</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column column-small-40 column-medium-25 mt-3 mt-medium-0">
                            <div className="background background-colours">
                                <div className="bg bg-fantasy">
                                    Fantasy
                                    <div className="background__info">
                                        <span>HEX: #F9F5EC</span>
                                        <span>RGB: 249, 245, 236</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column column-small-60 column-medium-35 mt-3 mt-medium-0">
                            <div className="background background-colours">
                                <div className="bg bg-canary">
                                    Canary
                                    <div className="background__info">
                                        <span>HEX: #EEFF58</span>
                                        <span>RGB: 238, 255, 88</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 mb-2">
                        <div className="column">
                            <p className="small">Secondary colours</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column column-small-half">
                            <div className="background background-colours">
                                <div className="bg bg-royal-blue text-white">
                                    Royal Blue
                                    <div className="background__info">
                                        <span>HEX: #3557E9</span>
                                        <span>RGB: 53, 86, 233</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column column-small-half mt-3 mt-small-0">
                            <div className="background background-colours">
                                <div className="bg bg-gumbo text-white">
                                    Gumbo
                                    <div className="background__info">
                                        <span>HEX: #78A4A6</span>
                                        <span>RGB: 120, 164, 166</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="column column-small-half column-medium-25">
                            <div className="background background-colours">
                                <div className="bg bg-carmine-pink text-white">
                                    Carmine Pink
                                    <div className="background__info">
                                        <span>HEX: #EB5342</span>
                                        <span>RGB: 235, 83, 66</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column column-small-half column-medium-25 mt-3 mt-small-0">
                            <div className="background background-colours">
                                <div className="bg bg-pharlap text-white">
                                    Pharlap
                                    <div className="background__info">
                                        <span>HEX: #A67878</span>
                                        <span>RGB: 166, 120, 120</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column column-small-half column-medium-25 mt-3 mt-medium-0">
                            <div className="background background-colours">
                                <div className="bg bg-white">
                                    White
                                    <div className="background__info">
                                        <span>HEX: #FFFFFF</span>
                                        <span>RGB: 255, 255, 255</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column column-small-half column-medium-25 mt-3 mt-medium-0">
                            <div className="background background-colours">
                                <div className="bg bg-black text-white">
                                    Black
                                    <div className="background__info">
                                        <span>HEX: #000000</span>
                                        <span>RGB: 0, 0, 0</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="column column-small-third column-large-20">
                            <div className="background background-low background-colours">
                                <div className="bg bg-gainsboro">
                                    Gainsboro
                                    <div className="background__info">
                                        <span>HEX: #DCDEE1</span>
                                        <span>RGB: 220, 222, 225</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column column-small-third column-large-20 mt-3 mt-small-0">
                            <div className="background background-low background-colours">
                                <div className="bg bg-blaze-orange">
                                    Blaze orange
                                    <div className="background__info">
                                        <span>HEX: #FF6800</span>
                                        <span>RGB: 255, 104, 0</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column column-small-third column-large-20 mt-3 mt-small-0">
                            <div className="background background-low background-colours">
                                <div className="bg bg-saffron-mango">
                                    Saffron mango
                                    <div className="background__info">
                                        <span>HEX: #FEBE58</span>
                                        <span>RGB: 254, 190, 88</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column offset-small-10 column-small-40 offset-large-0 column-large-20 mt-3 mt-large-0">
                            <div className="background background-low background-colours">
                                <div className="bg bg-london-hue">
                                    London Hue
                                    <div className="background__info">
                                        <span>HEX: #BDA1D0</span>
                                        <span>RGB: 189, 161, 208</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column column-small-40 column-large-20 mt-3 mt-large-0">
                            <div className="background background-low background-colours">
                                <div className="bg bg-bubble-gum">
                                    Bubble gum
                                    <div className="background__info">
                                        <span>HEX: #F4C5E2</span>
                                        <span>RGB: 244, 197, 226</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="column column-medium-20 tint-name">
                            <span>Eggshell</span>
                            <div className="line"></div>
                        </div>
                        <div className="column column-medium-80">
                            <div className="background background-tints">
                                <div className="bg bg-eggshell">
                                    <span>100%</span>
                                </div>
                                <div className="bg bg-eggshell-75">
                                    <span>75%</span>
                                </div>
                                <div className="bg bg-eggshell-50">
                                    <span>50%</span>
                                </div>
                                <div className="bg bg-eggshell-25">
                                    <span>25%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="column column-medium-20 tint-name">
                            <span>Canary</span>
                            <div className="line"></div>
                        </div>
                        <div className="column column-medium-80">
                            <div className="background background-tints">
                                <div className="bg bg-canary">
                                    <span>100%</span>
                                </div>
                                <div className="bg bg-canary-75">
                                    <span>75%</span>
                                </div>
                                <div className="bg bg-canary-50">
                                    <span>50%</span>
                                </div>
                                <div className="bg bg-canary-25">
                                    <span>25%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="column column-medium-20 tint-name">
                            <span>Royal blue</span>
                            <div className="line"></div>
                        </div>
                        <div className="column column-medium-80">
                            <div className="background background-tints">
                                <div className="bg bg-royal-blue">
                                    <span>100%</span>
                                </div>
                                <div className="bg bg-royal-blue-75">
                                    <span>75%</span>
                                </div>
                                <div className="bg bg-royal-blue-50">
                                    <span>50%</span>
                                </div>
                                <div className="bg bg-royal-blue-25">
                                    <span>25%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="column column-medium-20 tint-name">
                            <span>Gumbo</span>
                            <div className="line"></div>
                        </div>
                        <div className="column column-medium-80">
                            <div className="background background-tints">
                                <div className="bg bg-gumbo">
                                    <span>100%</span>
                                </div>
                                <div className="bg bg-gumbo-75">
                                    <span>75%</span>
                                </div>
                                <div className="bg bg-gumbo-50">
                                    <span>50%</span>
                                </div>
                                <div className="bg bg-gumbo-25">
                                    <span>25%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="column column-medium-20 tint-name">
                            <span>Carmine Pink</span>
                            <div className="line"></div>
                        </div>
                        <div className="column column-medium-80">
                            <div className="background background-tints">
                                <div className="bg bg-carmine-pink">
                                    <span>100%</span>
                                </div>
                                <div className="bg bg-carmine-pink-75">
                                    <span>75%</span>
                                </div>
                                <div className="bg bg-carmine-pink-50">
                                    <span>50%</span>
                                </div>
                                <div className="bg bg-carmine-pink-25">
                                    <span>25%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="column column-medium-20 tint-name">
                            <span>Pharlap</span>
                            <div className="line"></div>
                        </div>
                        <div className="column column-medium-80">
                            <div className="background background-tints">
                                <div className="bg bg-pharlap">
                                    <span>100%</span>
                                </div>
                                <div className="bg bg-pharlap-75">
                                    <span>75%</span>
                                </div>
                                <div className="bg bg-pharlap-50">
                                    <span>50%</span>
                                </div>
                                <div className="bg bg-pharlap-25">
                                    <span>25%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardBrandColours