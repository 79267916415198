import React from "react"

const LogoHead = () => {
    return (
        <section className="head">
            <div className="container">
                <div className="row">
                    <div className="column-medium-20 column-large-25 slide-from-bottom">
                        <div className="title">Logo</div>
                    </div>
                    <div className="column-medium-75 full-height slide-from-bottom mt-3 mt-medium-0">
                        <h1 className="column-large-75 ps-0">The logotype is one of our main brand assets and should be used as often as possible to build brand awareness.</h1>
                        <strong>Our primary logo consists of a bee symbol paired with a wordmark of our name. It is preferred to use the logo in its entirety, but if needed it’s possible to use the symbol and wordmark separately. </strong>
                        <a onClick={e => {document.getElementById("cardPrimaryLogo").scrollIntoView({behavior: "smooth"})}} className="arrow-down-button mt-7"></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LogoHead