import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import Lottie from 'lottie-react'

import lf30_e0yxomwr from '../lottie/lf30_e0yxomwr.json'
import lf30_7a1j3pin from '../lottie/lf30_7a1j3pin.json'
import lf30_xnzb03y4 from '../lottie/lf30_xnzb03y4.json'
import lf30_2mriwyox from '../lottie/lf30_2mriwyox.json'

const CardPictograms = ({content}) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardPictograms">
            <div className="card__header">
                <span>Pictograms</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row mt-4">
                        <div className="column">
                            <div className="border-around px-2 pt-2 pb-0">
                                <div className="row">
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie">
                                            <Lottie loop={true} animationData={lf30_e0yxomwr} />
                                            <span>Fast turnarounds</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <Lottie loop={true} animationData={lf30_7a1j3pin} />
                                            <span>Smooth workflows</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <Lottie loop={true} animationData={lf30_xnzb03y4} />
                                            <span>Affordable</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <Lottie loop={true} animationData={lf30_2mriwyox} />
                                            <span>Top Talent</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <img src="./img/icon-5.svg" alt="" />
                                            <span>Location</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <img src="./img/icon-6.svg" alt="" />
                                            <span>Industry</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <img src="./img/icon-7.svg" alt="" />
                                            <span>Founded</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <img src="./img/icon-8.svg" alt="" />
                                            <span>Money Saved</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <img src="./img/icon-9.svg" alt="" />
                                            <span>Time saved</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <img src="./img/icon-10.svg" alt="" />
                                            <span>Cookie</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <img src="./img/icon-11.svg" alt="" />
                                            <span>NPS score</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <img src="./img/icon-12.svg" alt="" />
                                            <span>Sub plan</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <img src="./img/icon-13.svg" alt="" />
                                            <span>AAA</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <img src="./img/icon-14.svg" alt="" />
                                            <span>Process</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around lottie lottie-small">
                                            <img src="./img/icon-15.svg" alt="" />
                                            <span>Brand assets</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around bg-inside-black lottie lottie-small">
                                            <img src="./img/icon-16.svg" alt="" />
                                            <span>Design</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around bg-inside-black lottie lottie-small">
                                            <img src="./img/icon-17.svg" alt="" />
                                            <span>Development</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-medium-25">
                                        <div className="border-around bg-inside-black lottie lottie-small">
                                            <img src="./img/icon-18.svg" alt="" />
                                            <span>SoMe</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardPictograms