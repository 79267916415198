import React from "react"

import TypographyHead from "../content/typographyHead"
import BottomNavbar from "../components/bottomNavbar/bottomNavbar"

import CardPolySans from "../content/cardPolySans"
import CardCanela from "../content/cardCanela"
import CardInter from "../content/cardInter"
import CardType from "../content/cardType"
import CardHelvetica from "../content/cardHelvetica"

class Typography extends React.Component {
    constructor(props){
      super(props)
    }

    render(){
        return (
            <>
                <TypographyHead />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="column">
                                <CardPolySans />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardCanela />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardInter />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardType />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <CardHelvetica />
                            </div>
                        </div>
                    </div>
                </section>
                <BottomNavbar 
                    updateLocation={this.props.updateLocation}
                    prevLink={"Brand Colour"}
                    prevHref={"/colours"}
                    nextLink={"Illustration Style"} 
                    nextHref={"/illustration"} />
            </>
        )
    }
}

export default Typography