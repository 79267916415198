import React from "react"
import { NavLink as Link } from "react-router-dom"

class NotFound extends React.Component {
    constructor(props){
      super(props)
    }

    render(){
        return (
            <section className="head">
                <div className="container">
                    <div className="row">
                        <div className="column-25">
                            <div className="title">404</div>
                        </div>
                        <div className="column-65">
                            <h1>The page you are looking for is not found.</h1>
                            <strong>Please make sure you enter the correct URL address or<div onClick={this.props.updateLocation}><Link className="" to="/">Go back to the homepage</Link></div></strong>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default NotFound