import React from "react"

const TypographyHead = () => {
    return (
        <section className="head">
            <div className="container">
                <div className="row">
                    <div className="column-medium-20 column-large-25 slide-from-bottom">
                        <div className="title">Typography</div>
                    </div>
                    <div className="column-medium-75 full-height slide-from-bottom mt-3 mt-medium-0">
                        <h1 className="column-large-75 ps-0">We use two fonts: PolySans as our main font &amp; <span className="ff-canela">Canela</span> as a compliment.</h1>
                        <strong>We’ll incorporate custom modifications to these typefaces where an artful touch is needed.</strong>
                        <a onClick={e => {document.getElementById("cardPolySans").scrollIntoView({behavior: "smooth"})}} className="arrow-down-button mt-7"></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TypographyHead