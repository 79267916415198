import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const CardPatterns = ({content}) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardPatterns">
            <div className="card__header">
                <span>Patterns</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row mt-4 px-2">
                        <div className="column column-medium-half border-row px-0">
                            <div className="border-around" style={{display: "flex"}}>
                                <img style={{margin: "auto"}} src="./img/illustration-14.svg" alt="" />
                            </div>
                        </div>
                        <div className="column column-medium-half border-row px-0 mt-3 mt-medium-0">
                            <div className="border-around">
                                <div className="row">
                                    <div className="column" style={{display: "flex"}}>
                                        <img style={{margin: "auto"}} src="./img/illustration-15.svg" alt="" />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="column-half px-2 px-small-1" style={{display: "flex"}}>
                                        <img style={{marginLeft: "auto", width: "100%"}} src="./img/illustration-16.svg" alt="" />
                                    </div>
                                    <div className="column-half px-2 px-small-1 mt-2 mt-small-0" style={{display: "flex"}}>
                                        <img style={{marginRight: "auto", width: "100%"}} src="./img/illustration-17.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mt-medium-0">
                        <div className="column border-column">
                            <div className="border-around">
                                <img src="./img/illustration-18.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardPatterns