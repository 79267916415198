import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const CardCategoryIcons = ({content}) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="CardCategoryIcons">
            <div className="card__header">
                <span>Category Icons</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content" id="category-icons">
                    <div className="row mt-4">
                        <div className="column">
                            <div className="border-around px-2 pt-2 pb-0">
                                <div className="row">
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/digital-ads.svg" alt="" />
                                            <span className="text-center">Digital Ads</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/presentations.svg" alt="" />
                                            <span className="text-center">Presentations</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/webdesign.svg" alt="" />
                                            <span className="text-center">Webdesign</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/packaging.svg" alt="" />
                                            <span className="text-center">Packaging</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/photo-editing.svg" alt="" />
                                            <span className="text-center">Photo editing</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/motion.svg" alt="" />
                                            <span className="text-center">Motion</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/brand-identity.svg" alt="" />
                                            <span className="text-center">Brand identity</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/print.svg" alt="" />
                                            <span className="text-center">Print</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/illustrations.svg" alt="" />
                                            <span className="text-center">Illustrations</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/merch.svg" alt="" />
                                            <span className="text-center">Merch</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/email-design.svg" alt="" />
                                            <span className="text-center">Email design </span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/3d.svg" alt="" />
                                            <span className="text-center">3D</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/ux.svg" alt="" />
                                            <span className="text-center">UX</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/concepts.svg" alt="" />
                                            <span className="text-center">Concepts</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/marketing-ads.svg" alt="" />
                                            <span className="text-center">Marketing &amp; ads</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/graphic-design.svg" alt="" />
                                            <span className="text-center">Graphic design</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/small-fixes.svg" alt="" />
                                            <span className="text-center">Small fixes</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/maintanance.svg" alt="" />
                                            <span className="text-center">Maintanance</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/brand.svg" alt="" />
                                            <span className="text-center">Brand</span>
                                        </div>
                                    </div>
                                    <div className="column-original-half column-small-third column-medium-20">
                                        <div className="border-around p-2 py-3 py-small-4 py-large-6">
                                            <img className="mb-3 mx-4 mx-large-6" src="./img/other.svg" alt="" />
                                            <span className="text-center">Other</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardCategoryIcons