import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const CardHelvetica = ({ content }) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    const [isActiveFw, setActiveFw] = useState(false)

    const toggleClassFw = () => {
        setActiveFw(!isActiveFw);
    };

    const [isActiveFs, setActiveFs] = useState(false)

    const toggleClassFs = () => {
        setActiveFs(!isActiveFs);
    };

    const [fontSizes, changeFontSizes] = window.innerWidth > 1079 ? useState({
        xs: 18,
        sm: 30,
        md: 48,
        lg: 60,
        xl: 90
    }) : useState({
        xs: 14,
        sm: 18,
        md: 22,
        lg: 28,
        xl: 36
    })
    
    const [fs, toggleFs] = useState(fontSizes.xl)
    const [fw, toggleFw] = useState(400)
    const [textValue, toggleTextValue] = useState("Type something here to try Helvetica")

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth > 1079) {

                toggleFs(90)
                toggleFw(400)

                changeFontSizes({
                    xs: 18,
                    sm: 30,
                    md: 48,
                    lg: 60,
                    xl: 90
                })
            } else {

                toggleFs(36)
                toggleFw(400)

                changeFontSizes({
                    xs: 14,
                    sm: 18,
                    md: 22,
                    lg: 28,
                    xl: 36
                })
            }
        })
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardHelvetica">
            <div className="card__header">
                <span>Standard font substitution</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row my-2">
                        <div className="column">
                            <p className="small">Unfortunately there are situations where we can’t use our fonts in a seamless way. 
                            <br/>In these instances, we substitute with the standard font Helvetica and use the same weight hierarchy as above. </p>
                        </div>
                    </div>
                    <div className="row mt-3 mt-medium-0">
                        <div className="column">
                            <div className="border-around px-2 py-5">
                                <div>
                                    <div className={`select${isActiveFw ? "": " hidden"}`} onClick={() => toggleClassFw()}>
                                        <span>Weight</span>
                                        <ul className="select-input" style={{height: 49.5 * 4 + 1}}>
                                            <li onClick={() => toggleFw(300)} className="select-input__item">300</li>
                                            <li onClick={() => toggleFw(400)} className="select-input__item">400</li>
                                            <li onClick={() => toggleFw(500)} className="select-input__item">500</li>
                                            <li onClick={() => toggleFw(700)} className="select-input__item">700</li>
                                        </ul>
                                    </div>
                                    <div className={`select${isActiveFs ? "": " hidden"}`} onClick={() => toggleClassFs()}>
                                        <span>Size</span>
                                        <ul className="select-input" style={{height: 49.5 * 5 + 1}}>
                                            <li onClick={() => toggleFs(fontSizes.xs)} className="select-input__item">{fontSizes.xs}</li>
                                            <li onClick={() => toggleFs(fontSizes.sm)} className="select-input__item">{fontSizes.sm}</li>
                                            <li onClick={() => toggleFs(fontSizes.md)} className="select-input__item">{fontSizes.md}</li>
                                            <li onClick={() => toggleFs(fontSizes.lg)} className="select-input__item">{fontSizes.lg}</li>
                                            <li onClick={() => toggleFs(fontSizes.xl)} className="select-input__item">{fontSizes.xl}</li>
                                        </ul>
                                    </div>
                                </div>
                                <textarea spellCheck="false" className={`ff-helvetica fs-${fs} fw-${fw}`} id="helvetica-textarea" value={textValue} onChange={(event) => toggleTextValue(event.target.value)}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardHelvetica