import React from "react"
import { NavLink as Link } from "react-router-dom"

class Navbar extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <nav onMouseLeave={() => this.props.hover('')} className={`navbar${this.props.opened ? " navbar-active" : ""}`}>
                <div className={`navbar__menu-button menu-button ${this.props.opened ? "menu-button-hidden" : ""}`} onClick={this.props.updateNavbar}>
                    <span className="menu-button__shape shape-1"></span>
                    <span className="menu-button__shape shape-2"></span>
                    <span className="menu-button__shape shape-3"></span>
                    Contents
                </div>
                <div onClick={this.props.updateLocation} className="navbar__brand brand">
                    <Link onMouseEnter={() => this.props.hover('branding')} to="/">Our Brand</Link>
                </div>
                <ul onClick={this.props.updateLocation} className="navbar__nav nav"> 
                    <span className="nav__title">Core elements</span>
                    <li className="nav__item">
                        <Link onMouseEnter={() => this.props.hover('logo')} to="/logo">
                            <span>Logo</span>
                        </Link>
                    </li>
                    <li className="nav__item">
                        <Link onMouseEnter={() => this.props.hover('colours')} to="/colours">
                            <span>Brand Colour</span>
                        </Link>
                    </li>
                    <li className="nav__item">
                        <Link onMouseEnter={() => this.props.hover('typography')} to="/typography">
                            <span>Typography</span>
                        </Link>
                    </li>
                    <li className="nav__item">
                        <Link onMouseEnter={() => this.props.hover('illustration')} to="/illustration">
                            <span>Illustration Style</span>
                        </Link>
                    </li>
                    <li className="nav__item">
                        <Link onMouseEnter={() => this.props.hover('photography')} to="/photography">
                            <span>Image Style</span>
                        </Link>
                    </li>
                    <li className="nav__item">
                        <Link onMouseEnter={() => this.props.hover('video')} to="/video">
                            <span>Video Style</span>
                        </Link>
                    </li>
                    {/* <li className="nav__item">
                        <Link onMouseEnter={() => this.props.hover('branding')} to="/branding">
                            <span>Branding</span>
                        </Link>
                    </li> */}
                    <li className="nav__item-big">
                        <Link to="#">
                            <span>Download assets</span>
                        </Link>
                    </li>
                </ul>
                <div className="navbar__close-button close-button" onClick={this.props.updateNavbar}>
                    Close
                    <span className="close-button__shape shape-1"></span>
                    <span className="close-button__shape shape-2"></span>
                </div>
            </nav>
        )
    }
}

export default Navbar