import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { gsap } from "gsap"

import Navbar from "./components/navbar/navbar"
import Header from "./components/header/header"
import Footer from "./components/footer/footer"

import MenuHover from "./content/menuHover"

import Brand from "./pages/brand"
// import Branding from "./pages/branding"
import Colours from "./pages/colours"
import Illustration from "./pages/illustration"
import Homepage from "./pages/notFound"
import Logo from "./pages/logo"
import Photography from "./pages/photography"
import Typography from "./pages/typography"
import Video from "./pages/video"

// const faviconTag = document.getElementById("faviconTag")
// const themeTag = document.getElementById("themeTag")

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
      navbarOpened: false,
      hoveredItem: '',
      location: '/'
    }

    this.updateLocation = this.updateLocation.bind(this)
    this.updateNavbar = this.updateNavbar.bind(this)
    this.updateHoveredItem = this.updateHoveredItem.bind(this)
  }

  // changeFavicon = () => {
  //   if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
  //     faviconTag.href = "./img/favicon/favicon-dark.svg"
  //     themeTag.content = "#F9F5EC"
  //   } 
  //   if (window.matchMedia("(prefers-color-scheme: light)").matches) {
  //     faviconTag.href = "./img/favicon/favicon.svg"
  //     themeTag.content = "#000000"
  //   }
  // }

  updateHoveredItem(item) {
    if (item == "branding") {
      this.setState({ hoveredItem: '' })
    } else {
      this.setState({ hoveredItem: item })
    }
  }

  updateNavbar() {
    this.state.navbarOpened ?
      this.setState({ navbarOpened: false, hoveredItem: '' }) :
      this.setState({ navbarOpened: true })
  }

  setupPage() {
    gsap.utils.toArray(".row").forEach(section => {
      if (section.querySelectorAll(".slide-from-bottom").length) {
        gsap.to(section.querySelectorAll(".slide-from-bottom"), {
          scrollTrigger: {
            trigger: section,
            start: "top 75%"
          },
          ease: "power3.out",
          duration: 2,
          opacity: 1,
          y: 0,
          stagger: 0.1,
          delay: 0
        })
      }
    })
    gsap.utils.toArray(".slide-from-left").forEach(section => {
        gsap.to(section, {
          scrollTrigger: {
            trigger: section,
            start: "top 75%"
          },
          ease: "power3.out",
          duration: 2,
          opacity: 1,
          x: 0,
          stagger: 0.2,
          delay: 0
        })
    })
    gsap.utils.toArray(".scheme").forEach(section => {
        gsap.to(section, {
          scrollTrigger: {
            trigger: section,
            start: "top 75%"
          },
          ease: "power3.out",
          duration: 2,
          y: 0,
          opacity: 1,
          delay: 0
        })
    })
  }

  updateLocation() {
    // this.history.pushState(null, location.pathname)
    if (this.state.location !== location.pathname) {
      gsap.to('section, .bottom-navbar, .footer', {
        ease: "power3.out",
        opacity: 0,
        duration: .3,
        onComplete: () => {
          document.getElementById("root").scrollIntoView()
          this.setState({
            hoveredItem: '',
            navbarOpened: false,
            location: location.pathname
          })
          gsap.fromTo('section, .bottom-navbar, .footer', {
            opacity: 0
          }, {
            ease: "power3.out",
            opacity: 1,
            duration: .1
          })
        }
      })
    }
  }

  checkScrolled = () => {
    const scroll = window.scrollY
    if (scroll > 64) {
      this.setState({ scrolled: true })
    } else {
      this.setState({ scrolled: false })
    }
  }

  handleScroll = (event) => {
    this.checkScrolled()
    event.preventDefault()
  }

  componentDidMount() {
    this.setupPage()
    this.checkScrolled()
    // this.changeFavicon()
    this.setState({ location: location.pathname })
    window.addEventListener("scroll", this.handleScroll)
    // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
    //   this.changeFavicon()
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== location.pathname) {
      this.setupPage()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    return (
      <>
        <MenuHover hoverItem={this.state.hoveredItem} />
        <Router>
          <Header updateLocation={this.updateLocation} scrolled={this.state.scrolled} />
          <Navbar hover={this.updateHoveredItem} opened={this.state.navbarOpened} updateNavbar={this.updateNavbar} updateLocation={this.updateLocation} />
          <Routes location={this.state.location}>
            <Route path="/" element={<Brand updateLocation={this.updateLocation} />} />
            {/* <Route path="/branding" element={<Branding updateLocation={this.updateLocation} />} /> */}
            <Route path="/colours" element={<Colours updateLocation={this.updateLocation} />} />
            <Route path="/illustration" element={<Illustration updateLocation={this.updateLocation} />} />
            <Route path="/logo" element={<Logo updateLocation={this.updateLocation} />} />
            <Route path="/photography" element={<Photography updateLocation={this.updateLocation} />} />
            <Route path="/typography" element={<Typography updateLocation={this.updateLocation} />} />
            <Route path="/video" element={<Video updateLocation={this.updateLocation} />} />
            <Route path="*" exact={true} element={<Homepage updateLocation={this.updateLocation} />} />
          </Routes>
        </Router>
        <Footer />
      </>
    )
  }
}

ReactDOM.render(
  <App />,
  document.querySelector('#root')
);