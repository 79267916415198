import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const CardType = ({ content }) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardType">
            <div className="card__header">
                <span>Type specimen: Ideal stack</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row mt-4">
                        <div className="column">
                            <div className="border-around">
                                <div className="row type">
                                    <div className="column column-small-20">
                                        <span className="type-name">H1</span>
                                        <span className="type-detail">PolySans Semibold 60/1,5/40</span>
                                    </div>
                                    <div className="column column-small-80">
                                        <span style={{ fontWeight: 600,fontSize: 60, letterSpacing: "0.04em" }}>This is creative tech</span>
                                    </div>
                                </div>
                                <div className="row mt-5 type">
                                    <div className="column column-small-20">
                                        <span className="type-name">H2</span>
                                        <span className="type-detail">PolySans Medium 48/1,5/40</span>
                                    </div>
                                    <div className="column column-small-80">
                                        <span style={{ fontWeight: 500,fontSize: 48, letterSpacing: "0.04em" }}>Get your own dedicated design team. </span>
                                    </div>
                                </div>
                                <div className="row mt-5 type">
                                    <div className="column column-small-20">
                                        <span className="type-name">H3</span>
                                        <span className="type-detail">PolySans Regular 30/1,5/40</span>
                                    </div>
                                    <div className="column column-small-80">
                                        <span style={{ fontSize: 30, letterSpacing: "0.04em" }}>Affordable quality design on time. </span>
                                    </div>
                                </div>
                                <div className="row mt-5 type">
                                    <div className="column column-small-20">
                                        <span className="type-name">H4</span>
                                        <span className="type-detail">PolySans Medium 18/1,5/40</span>
                                    </div>
                                    <div className="column column-small-80">
                                        <span style={{ fontWeight: 500,fontSize: 18, letterSpacing: "0.04em" }}>Last weeks stats</span>
                                    </div>
                                </div>
                                <div className="row mt-5 type">
                                    <div className="column column-small-20">
                                        <span className="type-name">P</span>
                                        <span className="type-detail">PolySans Medium 16/1,5/20</span>
                                    </div>
                                    <div className="column column-small-80">
                                        <span className="type-p-width">Min 45 - Max 75 characters per line</span>
                                        <span style={{ fontSize: 16, lineHeight: "19px", letterSpacing: "0.02em" }}>We do things differently, with a user-friendly platform, we offer businesses a way to expand without hiring in-house designers or expensive agencies. We deliver great designs, lightning fast for a set monthly fee.</span>
                                    </div>
                                </div>
                                <div className="row mt-5 type">
                                    <div className="column column-small-20">
                                        <span className="type-name">Quote Mark</span>
                                        <span className="type-detail">Desire Pro 100</span>
                                    </div>
                                    <div className="column column-small-80">
                                        <img style={{ width: 35, height: 100, paddingTop: 20 }} src="./img/quote.svg" alt="" />
                                    </div>
                                </div>
                                <div className="row mt-5 type">
                                    <div className="column column-small-20">
                                        <span className="type-name">Pull Quote</span>
                                        <span className="type-detail">PolySans Medium 50/1,5/20</span>
                                    </div>
                                    <div className="column column-small-80">
                                        <span style={{ fontWeight: 500,fontSize: 50, lineHeight: "55px", letterSpacing: "0.04em", maxWidth: 740 }}>With Klingit.com, we have saved about 80% in costs compared with our primus agency and projects has been delivered about twice as fast. Simply amazing! </span>
                                    </div>
                                </div>
                                <div className="row mt-5 type">
                                    <div className="column column-small-20">
                                        <span className="type-name">Attribution</span>
                                        <span className="type-detail">PolySans Medium 12/1,5/20</span>
                                    </div>
                                    <div className="column column-small-80">
                                        <span style={{ fontWeight: 500,fontSize: 12, lineHeight: "14px", letterSpacing: "0.04em", paddingTop: 20 }}>Tony Soprano</span>
                                        <span style={{ fontStyle: "italic", fontSize: 12, lineHeight: "14px", letterSpacing: "0.04em" }}>CEO, Klingit</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardType