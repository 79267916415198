import React from "react"
import { NavLink as Link } from "react-router-dom"

const BottomNavbar = ({updateLocation, prevLink, prevHref, nextLink, nextHref}) => {
        return (
            <nav className="bottom-navbar">
                <div className="container">
                    <ul onClick={updateLocation} className="bottom-navbar__bottom-nav bottom-nav">
                        <li className="bottom-nav__item arrow-left">
                            <Link to={prevHref}>{prevLink}</Link>
                        </li>
                        <li className="bottom-nav__item arrow-right">
                            <Link to={nextHref}>{nextLink}</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        )
}

export default BottomNavbar