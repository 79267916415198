import React from "react"

const PhotographyHead = () => {
    return (
        <section className="head">
            <div className="container">
                <div className="row">
                    <div className="column-medium-20 column-large-25 slide-from-bottom">
                        <div className="title">Image Style</div>
                    </div>
                    <div className="column-medium-75 full-height slide-from-bottom mt-3 mt-medium-0">
                        <h1>Like our brand voice, our image style lives fluidly on a spectrum of relatable to aspirational.</h1>
                        <strong>We mainly use images for two purposes; to show off our amazing team, or to convey inspiration and spark creativity and excitement. </strong>
                        <a onClick={e => {document.getElementById("cardPeople").scrollIntoView({behavior: "smooth"})}} className="arrow-down-button mt-7"></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PhotographyHead