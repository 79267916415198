import React from "react"
import { gsap } from "gsap"

let tl = gsap.timeline()

class MenuHover extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidUpdate() {
            if (this.props.hoverItem == "typography") {
                tl = gsap.timeline()
                tl.to('#typography-logo', {
                    ease: "none",
                    rotation: 360,
                    duration: 15,
                    repeat: -1
                })
            }
    }

    content() {
        switch (this.props.hoverItem) {
            case "logo": return (
                <div className="menu-hover__logo">
                    <div className="container">
                        <img src="../img/logo-inline.svg" alt=""></img>
                    </div>
                </div>
            )
            case "colours": return (
                <div className="menu-hover__colours container">
                    <div className="row">
                        <div className="column column-small-40 column-medium-half">
                            <div className="background">
                                <div className="bg bg-eggshell">
                                    Parchment
                                </div>
                            </div>
                        </div>
                        <div className="column column-small-30 column-medium-20 mt-3 mt-small-0">
                            <div className="background">
                                <div className="bg bg-fantasy">
                                    White Linen
                                </div>
                            </div>
                        </div>
                        <div className="column column-small-30 column-medium-30 mt-3 mt-small-0">
                            <div className="background">
                                <div className="bg bg-canary">Canary
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            case "typography": return (
                <div className="menu-hover__typography container">
                    <div className="row">
                        <div className="column offset-small-10 column-small-80 column-medium-60 offset-medium-20">
                            <span id="typography-logo">
                                <img src="./img/typography-logo.svg" alt=""></img>
                            </span>
                            <b className="h1">Get world-class creative work on-demand to <span className="text-decoration-canary">scale</span> your brand with ease — and at  <span className="text-decoration-canary">breakneck</span> speed.</b>
                        </div>
                    </div>
                </div>
            )
            case "illustration": return (
                <div className="menu-hover__illustration container">
                    <div className="row">
                        <div className="column offset-small-10 column-small-80 column-medium-60 offset-medium-20">
                            <div id="illustration-bubble">
                                <video autoPlay muted loop="loop" playsInline={true} preload="auto" className="mx-auto sm:mb-0" style={{ opacity: 1 }}>
                                    <source src="./video/KLingit_Glass_Loop_Alfa_1080X1080_Rotate.mov" type="video/quicktime" />
                                    <source src="./video/KLingit_Glass_Loop_Alfa_1080X1080_Rotate.webm" type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            )
            case "photography": return (
                <div className="menu-hover__photography container">
                    <div className="row">
                        <div className="column-half offset-medium-10 column-medium-40">
                            <img src="./img/photography-1.png"></img>
                        </div>
                        <div className="column-40 column-medium-30">
                            <img src="./img/photography-2.png"></img>
                            <img className="mt-3" src="./img/photography-3.png"></img>
                        </div>
                    </div>
                </div>
            )
            case "video": return (
                <div className="menu-hover__video container">
                    <div className="row">
                        <div className="column offset-small-10 column-small-80 column-medium-60 offset-medium-20">
                            <div id="video-box">
                                <img src="./img/video.png"></img>
                                <div className="bg bg-canary">How to work with design in 2022 </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return(
            <div className={`menu-hover${this.props.hoverItem == '' ? " hidden" : ""}`}>
                {this.content()}
            </div>
        )
    }
}

export default MenuHover