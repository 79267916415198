import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const Symbol = ({ content }) => {
    const [logo, toggleLogo] = useState(0)
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    const renderLogo = () => {
        switch (logo) {
            case 0: return (
                <div className="logo-container bg-white bg-picture">
                    <img style={{width: 134}} src="./img/logo.svg" alt=""></img>
                    <img style={{width: 134, opacity: 0}} src="./img/logo-white.svg" alt=""></img>
                </div>
            )
            case 1: return (
                <div className="logo-container bg-neutral bg-picture">
                    <img style={{width: 134, opacity: 0}} src="./img/logo.svg" alt=""></img>
                    <img style={{width: 134}} src="./img/logo-white.svg" alt=""></img>
                </div>
            )
            case 2: return (
                <div className="logo-container bg-canary bg-picture">
                    <img style={{width: 134}} src="./img/logo.svg" alt=""></img>
                    <img style={{width: 134, opacity: 0}} src="./img/logo-white.svg" alt=""></img>
                </div>
            )
            case 3: return (
                <div className="logo-container bg-eggshell bg-picture">
                    <img style={{width: 134}} src="./img/logo.svg" alt=""></img>
                    <img style={{width: 134, opacity: 0}} src="./img/logo-white.svg" alt=""></img>
                </div>
            )
            case 4: return (
                <div className="logo-container bg-picture bg-picture-active">
                    <img style={{width: 134}} src="./img/logo.svg" alt=""></img>
                    <img style={{width: 134, opacity: 0}} src="./img/logo-white.svg" alt=""></img>
                </div>
            )
        }
    }

    return (
        <div className="card slide-from-bottom" id="cardSymbol">
            <div className="card__header">
                <span>Symbol</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row my-2">
                        <div className="column">
                            <small>Variations</small>
                            <ul className="variations">
                                <li onClick={() => toggleLogo(0)} className={`${logo == 0 && "active"}`}>
                                    <span className="bg-white"></span>
                                </li>
                                <li onClick={() => toggleLogo(1)} className={`${logo == 1 && "active"}`}>
                                    <span className="bg-black"></span>
                                </li>
                                <li onClick={() => toggleLogo(2)} className={`${logo == 2 && "active"}`}>
                                    <span className="bg-canary"></span>
                                </li>
                                <li onClick={() => toggleLogo(3)} className={`${logo == 3 && "active"}`}>
                                    <span className="bg-eggshell"></span>
                                </li>
                                <li onClick={() => toggleLogo(4)} className={`${logo == 4 && "active"}`}>
                                    <span className="bg-multicolor"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="border-around p-2">
                        {renderLogo()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Symbol