import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const CardIllustrations = ({content}) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardIllustrations">
            <div className="card__header">
                <span>Illustrations</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content" id="illustrations-container">
                    <div className="row mt-4 px-2">
                        <div className="column column-medium-half px-0 border-row" id="illustration-1">
                            <div className="border-around">
                                <video autoPlay muted loop="loop" playsInline={true} preload="auto" className="mx-auto sm:mb-0" style={{ opacity: 1 }}>
                                    <source src="./video/KlingitHero_Spin_V06.mov" type="video/quicktime" />
                                    <source src="./video/KlingitHero_Spin_V06.webm" type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                        <div className="column column-medium-half px-0 border-row" id="illustration-2">
                            <div className="border-around px-0 border-row">
                                <video autoPlay muted loop="loop" playsInline={true} preload="auto" className="mx-auto sm:mb-0" style={{ opacity: 1 }}>
                                    <source src="./video/KLingit_Glass_Loop_Alfa_1080X1080_Rotate.mov" type="video/quicktime" />
                                    <source src="./video/KLingit_Glass_Loop_Alfa_1080X1080_Rotate.webm" type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                                <img src="./img/illustration-10.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row px-2">
                        <div className="column column-medium-half px-0 border-row" id="illustration-3">
                            <div className="border-around">
                                <img src="./img/illustration-19.png" alt="" />
                            </div>
                        </div>
                        <div className="column column-medium-half px-0 border-row" id="illustration-4">
                            <div className="border-around px-0 border-row">
                                <video autoPlay muted loop="loop" playsInline={true} preload="auto" className="mx-auto sm:mb-0" style={{ opacity: 1 }}>
                                    <source src="./video/Rectangles.mov" type="video/quicktime" />
                                    <source src="./video/Rectangles.webm" type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className="row px-2">
                        <div className="column column-medium-half px-0 border-row" id="illustration-5">
                            <div className="border-around">
                                <video autoPlay muted loop="loop" playsInline={true} preload="auto" className="mx-auto sm:mb-0" style={{ opacity: 1 }}>
                                    <source  src="./video/KLingit_Cloud_Alfa.mov" type="video/quicktime" />
                                    <source  src="./video/KLingit_Cloud_Alfa.webm" type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                        <div className="column column-medium-half px-0 border-row" id="illustration-6">
                            <div className="border-around px-0 border-row">
                                <img src="./img/illustration-8.svg" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row px-2">
                        <div className="column column-medium-half px-0 border-row" id="illustration-5">
                            <div className="border-around">
                                <img src="./img/illustration-12.png" alt="" />
                            </div>
                        </div>
                        <div className="column column-medium-half px-0 border-row" id="illustration-6">
                            <div className="border-around px-0 border-row">
                                <img src="./img/illustration-13.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardIllustrations