import React from "react"
import { gsap } from "gsap"

let tl = gsap.timeline()

class Chat extends React.Component {
    constructor(props) {
        super(props)
    }

    startChat() {
        tl.restart()

        tl.set('#chat1, #chat1_typing + g, #chat2, #chat2_typing, #chat2_typing + g, #chat3, #chat3_typing, #chat3_typing + g', {
            opacity: 0
        })

        tl.to('#chat1', {
            duration: .3,
            opacity: 1,
            delay: 0
        })
        tl.to('#chat1_typing circle', {
            duration: .3,
            y: -3,
            delay: 0,
            stagger: .1,
            yoyo: true,
            repeat: -1
        })
        tl.to('#chat2_typing circle', {
            duration: .3,
            y: -3,
            delay: 0,
            stagger: .1,
            yoyo: true,
            repeat: -1
        })
        tl.to('#chat3_typing circle', {
            duration: .3,
            y: -3,
            delay: 0,
            stagger: .1,
            yoyo: true,
            repeat: -1
        })

        tl.to('#chat1_typing', {
            duration: .3,
            opacity: 0,
            delay: 0
        })
        tl.to('#chat1_typing + g', {
            duration: .2,
            opacity: 1,
            x: 0,
            delay: .3
        })

        tl.to('#chat2, #chat2_typing', {
            duration: .3,
            opacity: 1,
            delay: .5
        })
        tl.to('#chat2_typing', {
            duration: .3,
            opacity: 0,
            delay: 1.5
        })
        tl.to('#chat2_typing + g', {
            duration: .2,
            opacity: 1,
            x: 0,
            delay: .3
        })

        tl.to('#chat3, #chat3_typing', {
            duration: .3,
            opacity: 1,
            delay: .5
        })
        tl.to('#chat3_typing', {
            duration: .3,
            opacity: 0,
            delay: 1.5
        })
        tl.to('#chat3_typing + g', {
            duration: .2,
            opacity: 1,
            x: 0,
            delay: .3
        })
    }

    componentDidMount() {
        tl = gsap.timeline()
        tl.set('#chat1, #chat1_typing + g, #chat2, #chat2_typing, #chat2_typing + g, #chat3, #chat3_typing, #chat3_typing + g', {
            opacity: 0
        })
        tl.to('#chat', {
            scrollTrigger: {
                trigger: '#chat',
                start: "top 75%",
                onEnter: () => this.startChat()
            },
            opacity: 1
        })
    }

    render() {
        return (
            <div id="chat" className="mt-2 irl-element">
                <svg xmlns="http://www.w3.org/2000/svg" width="483" height="704" viewBox="0 0 483 704">
                    <g id="Group_1554" transform="translate(-181 -5538)">
                        <g id="avatar_2021-11-10_kl._16.52.04" transform="translate(181 5538)" fill="#f9f5ec" stroke="#000" strokeWidth="1">
                            <rect width="483" height="704" stroke="none" />
                            <rect x="0.5" y="0.5" width="482" height="703" fill="none" />
                        </g>
                        <image style={{ opacity: 0 }} id="chat1" width="63" height="63" rx="31.5" transform="translate(206, 5595)" xlinkHref="../img/avatar1.png"></image>
                        <g id="chat1_typing">
                            <circle r="3" cx="310" cy="5630" fill="#241c15"></circle>
                            <circle r="3" cx="300" cy="5630" fill="#241c15"></circle>
                            <circle r="3" cx="290" cy="5630" fill="#241c15"></circle>
                        </g>
                        <g style={{ opacity: 0, transform: "translateX(-10px)" }}>
                            <text id="Jakob_Eriksson" transform="translate(286 5606)" fill="#241c15" fontSize="12" fontFamily="Helvetica" letterSpacing="0.04em"><tspan x="0" y="9">Jakob Eriksson</tspan></text>
                            <rect id="Rectangle_1438" width="333" height="166" rx="4" transform="translate(286 5627)" fill="#fff" />
                            <text id="_10.30" transform="translate(386 5606)" fill="#241c15" fontSize="12" fontFamily="Helvetica" letterSpacing="0.04em"><tspan x="0" y="9">10.30</tspan></text>
                            <text id="" transform="translate(300 5642)" fill="#241c15" fontSize="16" fontFamily="Helvetica" letterSpacing="0.04em"><tspan x="0" y="12">Hi Carl! Attached are 2 different </tspan><tspan x="0" y="30">suggestions for the company flyer. </tspan><tspan x="0" y="66">Please get back to me with your input</tspan><tspan x="0" y="84">tomorrow so we have time for </tspan><tspan x="0" y="100">revisions before the set deadline.</tspan><tspan x="0" y="136">Thanks!</tspan></text>
                        </g>
                        <image style={{ opacity: 0 }} id="chat2" width="63" height="63" rx="31.5" transform="translate(568, 5832)" xlinkHref="../img/avatar2.png"></image>
                        <g id="chat2_typing">
                            <circle r="3" cx="530" cy="5858" fill="#241c15"></circle>
                            <circle r="3" cx="540" cy="5858" fill="#241c15"></circle>
                            <circle r="3" cx="550" cy="5858" fill="#241c15"></circle>
                        </g>
                        <g style={{ opacity: 0, transform: "translateX(10px)" }}>
                            <rect id="Rectangle_1461" width="333" height="96" rx="4" transform="translate(220 5849)" fill="#fff" />
                            <text id="Client_Carl" transform="translate(353 5828)" fill="#241c15" fontSize="12" fontFamily="Helvetica" letterSpacing="0.04em"><tspan x="79.184" y="9">Client Carl</tspan></text>
                            <text id="_10.30-2" transform="translate(505 5828)" fill="#241c15" fontSize="12" fontFamily="Helvetica" letterSpacing="0.04em"><tspan x="8.051" y="9">10.30</tspan></text>
                            <text transform="translate(234 5864)" fill="#241c15" fontSize="16" fontFamily="Helvetica" letterSpacing="0.04em"><tspan x="0" y="12">Hey! Thanks, these look great.</tspan><tspan x="0" y="48">We really like option 1, but </tspan><tspan x="0" y="66">can we use the image from option 2?</tspan></text>
                        </g>
                        <image style={{ opacity: 0 }} id="chat3" width="63" height="63" rx="31.5" transform="translate(206, 5982)" xlinkHref="../img/avatar1.png"></image> //36
                        <g id="chat3_typing">
                            <circle r="3" cx="310" cy="6017" fill="#241c15"></circle>
                            <circle r="3" cx="300" cy="6017" fill="#241c15"></circle>
                            <circle r="3" cx="290" cy="6017" fill="#241c15"></circle>
                        </g>
                        <g style={{ opacity: 0, transform: "translateX(-10px)" }}>
                            <rect id="Rectangle_1462" width="333" height="149" rx="4" transform="translate(286 6014)" fill="#fff" />
                            <text id="Jakob_Eriksson-2" transform="translate(286 5993)" fill="#241c15" fontSize="12" fontFamily="Helvetica" letterSpacing="0.04em"><tspan x="0" y="9">Jakob Eriksson</tspan></text>
                            <text id="_10.30-3" transform="translate(386 5993)" fill="#241c15" fontSize="12" fontFamily="Helvetica" letterSpacing="0.04em"><tspan x="0" y="9">10.30</tspan></text>
                            <text transform="translate(300 6029)" fill="#241c15" fontSize="16" fontFamily="Helvetica" letterSpacing="0.04em"><tspan x="0" y="12">Hi Carl!</tspan><tspan x="0" y="48">Of course, we will adjust right away</tspan><tspan x="0" y="66">and get back to you this afternoon</tspan><tspan x="0" y="84">with a print-ready file.</tspan><tspan x="0" y="118">Thanks!</tspan></text>
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

export default Chat