import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const CardCommunication = ({content}) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardMessaging">
            <div className="card__header">
                <span>Messaging House</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row my-2">
                        <div className="column column-small-half">
                            <p className="small pe-medium-7">Our messaging house is a hierarchical structure facilitating the strategic production of marketing messages. It ensures that our communication is concise and always on brand.</p>
                        </div>
                    </div>
                    <div className="border-around text-center">
                        <div className="row">
                            <div className="column column-medium-60 offset-medium-20 column-large-third offset-large-third">
                                <small className="mb-3">Main Message</small>
                                <b className="mb-2">Creativity without limitations</b>
                                <p className="mb-3">With our tech-enabled team of expert creatives readily available, it’s easier, faster and more affordable than ever to grow a brand beyond expectations.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column column-small-third offset-small-third mb-3">
                                <div className="scheme" id="scheme-shape-top"></div>
                                <small className="scheme">Supporting messages</small>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column column-medium-third scheme">
                                <b className="regular mb-2">A global home to ambitious creatives</b>
                                <p className="regular">We are a remote creative services company and home to ambitious people from around the world — an ideal place for top creatives to bring their skills and careers to the next level.</p>
                            </div>
                            <div className="column column-medium-third scheme mt-3 mt-medium-0">
                                <b className="regular mb-2">The better way to scale fast</b>
                                <p className="regular">Unlike expensive agencies or slow hires, our scalable, subscription-based solutions provide the sweet spot in which you can afford to move fast while maintaining brand consistency.</p>
                            </div>
                            <div className="column column-medium-third scheme mt-3 mt-medium-0">
                                <b className="regular mb-2">The All-In-One platform for creative efforts</b>
                                <p className="regular">Our constantly evolving platform streamlines the creative process and helps companies develop, manage, and grow their brand from one place — and at breakneck speed.</p>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="column column-small-third offset-small-third">
                                <div className="scheme" id="scheme-shape-middle"></div>
                                <small className="scheme">Proof Points</small>
                                <div className="scheme" id="scheme-shape-bottom"></div>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <div className="column column-small-half column-medium-20 offset-medium-20 scheme mt-3">
                                <b className="small mb-2">Extensive capabilities</b>
                                <p className="extra-small">In-depth expertise within a wide area of brand marketing from top creatives around the world</p>
                            </div>
                            <div className="column column-small-half column-medium-20 scheme mt-3">
                                <b className="small mb-2">Cost-effective</b>
                                <p className="extra-small">Predictable flat-rate pricing and a more affordable way to secure high-quality assets</p>
                            </div>
                            <div className="column column-small-half column-medium-20 scheme mt-3">
                                <b className="small mb-2">Fast turnarounds</b>
                                <p className="extra-small">Outstanding on-brand assets reliably delivered within 24 to 48 hours from briefing</p>
                            </div>
                            <div className="column column-small-half column-medium-20 offset-medium-20 scheme mt-3">
                                <b className="small mb-2">Scalable solutions</b>
                                <p className="extra-small">A flexible subscription model suitable for any and all enterprises, regardless of size and growth rate</p>
                            </div>
                            <div className="column column-small-half column-medium-20 scheme mt-3">
                                <b className="small mb-2">Smooth workflow</b>
                                <p className="extra-small">Streamlined processes and real-time collaboration between internal and external teams</p>
                            </div>
                            <div className="column column-small-half column-medium-20 scheme mt-3">
                                <b className="small mb-2">Secure asset storage</b>
                                <p className="extra-small">A purpose-built platform enabling ideal structuring, overview and sharing of brand assets</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardCommunication