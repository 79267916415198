import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const CardPeople = ({content}) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardPeople">
            <div className="card__header">
                <span>People</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row mt-4 px-2">
                        <div className="column column-medium-third mt-3 mt-medium-0 px-0 border-row">
                            <div className="border-around">
                                <img src="./img/photography-1.png" alt=""></img>
                            </div>
                        </div>
                        <div className="column column-medium-third mt-3 mt-medium-0 px-0 border-row">
                            <div className="border-around">
                                <img src="./img/photography-4.png" alt=""></img>
                            </div>
                        </div>
                        <div className="column column-medium-third mt-3 mt-medium-0 px-0 border-row">
                            <div className="border-around">
                                <img src="./img/photography-5.png" alt=""></img>
                            </div>
                        </div>
                    </div>
                    <div className="row px-2 border-column">
                        <div className="column column-medium-third mt-3 mt-medium-0 px-0 border-row">
                            <div className="border-around">
                                <img src="./img/photography-6.png" alt=""></img>
                            </div>
                        </div>
                        <div className="column column-medium-third mt-3 mt-medium-0 px-0 border-row">
                            <div className="border-around">
                                <img src="./img/photography-7.png" alt=""></img>
                            </div>
                        </div>
                        <div className="column column-medium-third mt-3 mt-medium-0 px-0 border-row">
                            <div className="border-around">
                                <img className="m-auto" src="./img/skarmavbild.png" alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardPeople