import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const Usage = ({ fold }) => {
    const [closed, toggleClosed] = useState(fold)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardSymbol">
            <div className="card__header">
                <span>Usage</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row my-2">
                        <div className="column column-medium-half">
                            <div className="border-around p-2 mt-3">
                                <div className="logo-container bg-white p-5">
                                    <img src="./img/usage-1.svg" alt=""></img>
                                </div>
                            </div>
                        </div>
                        <div className="column column-medium-40">
                            <div className="border-around p-2 mt-3">
                                <div className="logo-container bg-white p-5">
                                    <img src="./img/usage-2.svg" alt=""></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Usage