import React from "react"

const ColoursHead = () => {
    return (
        <section className="head">
            <div className="container">
                <div className="row">
                    <div className="column column-medium-20 column-large-25 slide-from-bottom">
                        <div className="title">Brand Colour</div>
                    </div>
                    <div className="column column-medium-60 full-height slide-from-bottom mt-3 mt-medium-0">
                        <h1>Our neon yellow is our most identifiable colour. </h1>
                        <strong>With black, white and neon yellow as our primary colours, we also work with secondary, complementary colours such as beige, blues and reds.</strong>
                        <a onClick={e => {document.getElementById("cardBrandColours").scrollIntoView({behavior: "smooth"})}} className="arrow-down-button mt-7"></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ColoursHead