import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const CardIcons = ({ content }) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardIcons">
            <div className="card__header">
                <span>STANDRAD ICONS by FONT AWESOME</span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row mt-4">
                        <div className="column">
                            <div className="border-around icons-container p-3">
                                <span className="icon">
                                    <img src="./img/icons/atom-simple.svg" />
                                </span>
                                <span className="icon"><img src="./img/icons/alien.svg" /></span>
                                <span className="icon"><img src="./img/icons/arrow-right-long.svg" /></span>
                                <span className="icon"><img src="./img/icons/burger-cheese.svg" /></span>
                                <span className="icon"><img src="./img/icons/flower-daffodil.svg" /></span>
                                <span className="icon"><img src="./img/icons/star-shooting.svg" /></span>
                                <span className="icon"><img src="./img/icons/bone.svg" /></span>
                                <span className="icon"><img src="./img/icons/award.svg" /></span>
                                <span className="icon"><img src="./img/icons/bullhorn.svg" /></span>
                                <span className="icon"><img src="./img/icons/book-blank.svg" /></span>
                                <span className="icon"><img src="./img/icons/bandage.svg" /></span>
                                <span className="icon"><img src="./img/icons/bell.svg" /></span>
                                <span className="icon"><img src="./img/icons/carrot.svg" /></span>
                                <span className="icon"><img src="./img/icons/apple-core.svg" /></span>
                                <span className="icon"><img src="./img/icons/wand-magic-sparkles.svg" /></span>
                                <span className="icon"><img src="./img/icons/monkey.svg" /></span>
                                <span className="icon"><img src="./img/icons/cat-space.svg" /></span>
                                <span className="icon"><img src="./img/icons/pizza-slice.svg" /></span>
                                <span className="icon"><img src="./img/icons/trash-can.svg" /></span>
                                <span className="icon"><img src="./img/icons/yin-yang.svg" /></span>
                                <span className="icon"><img src="./img/icons/meteor.svg" /></span>
                                <span className="icon"><img src="./img/icons/ufo.svg" /></span>
                                <span className="icon"><img src="./img/icons/venus.svg" /></span>
                                <span className="icon"><img src="./img/icons/gift.svg" /></span>
                                <span className="icon"><img src="./img/icons/poo.svg" /></span>
                                <span className="icon"><img src="./img/icons/volume-off.svg" /></span>
                                <span className="icon"><img src="./img/icons/glasses-round.svg" /></span>
                                <span className="icon"><img src="./img/icons/clothes-hanger.svg" /></span>
                                <span className="icon"><img src="./img/icons/spade.svg" /></span>
                                <span className="icon"><img src="./img/icons/french-fries.svg" /></span>
                                <span className="icon"><img src="./img/icons/hat-cowboy.svg" /></span>
                                <span className="icon"><img src="./img/icons/game-console-handheld.svg" /></span>
                                <span className="icon"><img src="./img/icons/fish.svg" /></span>
                                <span className="icon"><img src="./img/icons/lips.svg" /></span>
                                <span className="icon"><img src="./img/icons/dolphin.svg" /></span>
                                <span className="icon"><img src="./img/icons/medal.svg" /></span>
                                <span className="icon"><img src="./img/icons/chair-office.svg" /></span>
                                <span className="icon"><img src="./img/icons/chess-knight-piece.svg" /></span>
                                <span className="icon"><img src="./img/icons/castle.svg" /></span>
                                <span className="icon"><img src="./img/icons/cloud-sun.svg" /></span>
                                <span className="icon"><img src="./img/icons/pen-ruler.svg" /></span>
                                <span className="icon"><img src="./img/icons/comment-dollar.svg" /></span>
                                <span className="icon"><img src="./img/icons/horse.svg" /></span>
                                <span className="icon"><img src="./img/icons/chart-pie-simple.svg" /></span>
                                <span className="icon"><img src="./img/icons/face-frown-open.svg" /></span>
                                <span className="icon"><img src="./img/icons/check.svg" /></span>
                                <span className="icon"><img src="./img/icons/cloud-drizzle.svg" /></span>
                                <span className="icon"><img src="./img/icons/fire.svg" /></span>
                                <span className="icon"><img src="./img/icons/starfighter.svg" /></span>
                                <span className="icon"><img src="./img/icons/magnifying-glass.svg" /></span>
                                <span className="icon"><img src="./img/icons/tag.svg" /></span>
                                <span className="icon"><img src="./img/icons/duck.svg" /></span>
                                <span className="icon"><img src="./img/icons/ice-cream.svg" /></span>
                                <span className="icon"><img src="./img/icons/skull-crossbones.svg" /></span>
                                <span className="icon"><img src="./img/icons/drum.svg" /></span>
                                <span className="icon"><img src="./img/icons/heart.svg" /></span>
                                <span className="icon"><img src="./img/icons/cloud-moon.svg" /></span>
                                <span className="icon"><img src="./img/icons/elephant.svg" /></span>
                                <span className="icon"><img src="./img/icons/moon.svg" /></span>
                                <span className="icon"><img src="./img/icons/sun-bright.svg" /></span>
                                <span className="icon"><img src="./img/icons/egg-fried.svg" /></span>
                                <span className="icon"><img src="./img/icons/location-dot.svg" /></span>
                                <span className="icon"><img src="./img/icons/message-smile.svg" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardIcons