import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"

const CardTonality = ({content}) => {
    const [closed, toggleClosed] = useState(false)
    const thisElement = useRef(null)
    const thisChildElement = useRef(null)

    useEffect(() => {
        if (closed) {
            gsap.to(thisElement.current, {
                height: 0,
                ease: "ease",
                duration: .3
            })
        } else {
            gsap.to(thisElement.current, {
                height: thisChildElement.current.clientHeight,
                ease: "ease",
                duration: .3,
                onComplete: () => {
                    if (thisElement.current !== null)
                        thisElement.current.style.height = "auto"
                }
            })
        }
    }, [closed])

    return (
        <div className="card slide-from-bottom" id="cardTonality">
            <div className="card__header">
                <span>Tone of Voice </span>
                <div className={`card__close-button${closed ? " active" : ""}`} onClick={() => toggleClosed(!closed)}>{closed ? "Open" : "Close"}</div>
            </div>
            <div ref={thisElement} className="card__inner">
                <div ref={thisChildElement} className="card__content">
                    <div className="row my-2">
                        <div className="column column-small-half">
                            <p className="small pe-medium-7">These characteristics don’t describe what we talk about, but how we talk about it. It’s meant to guide both writing and verbal communication and plays an important part in building our brand personality.</p>
                        </div>
                    </div>
                    <div className="border-around">
                        <div className="row mb-3">
                            <div className="column column-medium-70 slide-from-left">
                                <span className="number">1.</span>
                                <span className="small-title">We tend to be pretty carefree in our expression,</span>
                                <p className="regular" style={{width: 320}}>As a wild merge of cultures and nationalities united by creativity, corporate talk simply isn’t for us — so don’t constrain yourself.</p>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="column column-medium-70 offset-medium-25 slide-from-left">
                                <span className="number">2.</span>
                                <span className="small-title">but with a firm footing in the professional world.</span>
                                <p className="regular" style={{width: 360}}>Our work has an enormous impact on multi-million dollar businesses, which demands some thought. By all means, have fun and be creative — but know that a pinch of professionalism goes a long way.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column column-medium-half offset-medium-half slide-from-left">
                                <span className="number">3.</span>
                                <span className="small-title">And above all, we’re easy!</span>
                                <p className="regular" style={{width: 320}}>To put it frankly, quick and easy are our main selling points. So don’t get stuck in the weeds — make your point and trust the product.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardTonality