import React from "react"

import ColoursHead from "../content/coloursHead"
import BottomNavbar from "../components/bottomNavbar/bottomNavbar"

import CardBrandColours from "../content/cardBrandColours"

class Colours extends React.Component {
    constructor(props){
      super(props)
    }

    render(){
        return (
            <>
                <ColoursHead />
                <section className="head">
                    <div className="container pt-0">
                        <div className="row">
                            <div className="column">
                                <CardBrandColours />
                            </div>
                        </div>
                    </div>
                </section>
                <BottomNavbar 
                    updateLocation={this.props.updateLocation}
                    prevLink={"Logo"}
                    prevHref={"/logo"}
                    nextLink={"Typography"} 
                    nextHref={"/typography"} />
            </>
        )
    }
}

export default Colours