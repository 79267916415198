import React from "react"

const BrandHead = () => {
    return (
        <section className="head">
            <div className="container">
                <div className="row">
                    <div className="column-medium-20 column-large-25 slide-from-bottom">
                        <div className="title">Our Brand</div>
                    </div>
                    <div className="column-medium-75 full-height slide-from-bottom mt-3 mt-medium-0">
                        <h1>Creativity liberated by technology</h1>
                        <strong className="mb-2">Our company was founded on a mission to reconstruct the creative process. A process that has long been held back due to extensive, vague agency processes as well as companies’ ambitiously wide roles not being attainable when hiring talent.</strong>
                        <strong className="mb-7">Our solution is simple: We built a global team of top talents within all areas of design, experienced creative and strategic project managers, and a technologically advanced digital platform. Allowing us to deliver high quality design, with fast turnarounds at a highly competitive price point.</strong>
                        <a onClick={e => {document.getElementById("cardCommunication").scrollIntoView({behavior: "smooth"})}} className="arrow-down-button mt-auto"></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BrandHead