import React from "react"

const Footer = () => {
    return(
        <div className="footer">
            <div className="footer__brand"></div>
            <small>©{(new Date().getFullYear())} All Rights Reserved. Klingit® is a registered trademark.</small>
        </div>
    )
}

export default Footer