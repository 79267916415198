import React from "react"
import { NavLink as Link } from "react-router-dom"

const Header = ({scrolled, updateLocation}) => {
    return(
        <div onClick={updateLocation} className={`header${scrolled ? " header-scrolled" : ""}`}>
            <Link to="/" className="header__inner">
                <div className="header__brand"></div>
                <span>Klingit Creative Expression</span>
            </Link>
        </div>
    )
}

export default Header